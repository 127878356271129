import { printDate } from "../../../services/general"

export const doctorEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    name,
    prcNumber,
    department,
    doctorEmail,
    secretaryName,
    specialization,
    hospitalClinic,
    clinicSchedule,
    otherDepartment,
    secretaryrEmail,
    doctorMobileNumber,
    otherSpecialization,
    secretaryrMobileNumber,
    wouldLikeToReceiveUpdates,
    isAffiliatedWithAGovernment,
  } = enrollmentData

  let parsedUpdates = wouldLikeToReceiveUpdates?.length > 0 ? "Yes" : "No"

  let doctorSpecialization =
    specialization.value === "Other (please specify)"
      ? otherSpecialization
      : specialization.value

  let parsedDepartment =
    department.value === "Other (please specify)"
      ? otherDepartment
      : department.value

  return `Personal Details
Name: ${name}
PRC License Number: ${prcNumber}
Specialization: ${doctorSpecialization}
Hospital: ${hospitalClinic}
Department: ${parsedDepartment}
Government-Affiliated Institution: ${isAffiliatedWithAGovernment}
Clinic Schedule: ${clinicSchedule}
----
Doctor’s Email: ${doctorEmail}
Doctor’s Mobile Number: ${doctorMobileNumber}
Medical Secretary: ${secretaryName}
Medical Secretary’s Email: ${secretaryrEmail || "N/A"}
Medical Secretary’s Contact Number: ${secretaryrMobileNumber}`
}

export const patientEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    prescribingDoctor,
    firstTimeConsulting,
    prescribedTests,
    clinicHospital,
    packageAvailed,
    followUpConsultation,
    parsedHomePackage,
    availHomePackage,
    preferredLaboratory,

    birthday,
    sex,
    branch,
    preferredAppointmentDate,
    preferredAppointmentTime,
    homeAddress,
  } = enrollmentData

  let { tests, rawText } = packageAvailed
  let { month, date, year } = followUpConsultation

  let parsedTest = ""
  tests.forEach(test => {
    parsedTest += `- ${test}\n`
  })

  let parsedFollowUpConsultation = ""
  if (month.value && date.value && year)
    parsedFollowUpConsultation = `${month.value} ${date.value}, ${year}`

  let hasHomePackage = ""

  let laboratoryPreferredDate =
    preferredLaboratory?.value !== "Hi-Precision Diagnostics Centre"
      ? `Preferred Appointment Date: ${printDate(
          preferredAppointmentDate
        )}\nPreferred Appointment Time: ${preferredAppointmentTime?.value}`
      : ""

  if (preferredLaboratory?.value === "Hi-Precision Diagnostics Centre")
    hasHomePackage = `Birthday: ${printDate(birthday)}
Sex: ${sex}
Preferred Branch: ${branch?.value}
Preferred Appointment Date: ${printDate(preferredAppointmentDate)}
Preferred Appointment Time: ${preferredAppointmentTime?.value}
Availed Home Service: ${parsedHomePackage || "NO"}`

  if (
    availHomePackage?.length > 0 &&
    preferredLaboratory?.value === "Hi-Precision Diagnostics Centre"
  ) {
    hasHomePackage += `
Street Address: ${homeAddress?.streetAddress}
Province: ${homeAddress?.province?.value}
City: ${homeAddress?.city?.value}
Barangay: ${homeAddress?.barangay?.value}`
  }

  return `Patient Information -----
First Name: ${firstName}
Last Name: ${lastName}
Middle Initial: ${middleInitial || "N/A"}
Email: ${emailAddress || "N/A"}
Mobile Number: ${mobileNumber}

Doctor Information -----
Prescribing Doctor: ${prescribingDoctor}
First Time Consulting Prescribing Doctor: ${firstTimeConsulting}
Prescribed Tests: ${prescribedTests}
Hospital: ${clinicHospital}
Schedule of Follow-up Consultation: ${parsedFollowUpConsultation || "N/A"}

Diagnostic Test Package -----
${parsedTest}
${rawText && "Preferred Laboratory -----"}
${rawText}
${laboratoryPreferredDate}
${hasHomePackage}`
}
